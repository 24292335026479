import React, { Component } from "react";
import { FormGroup, FormLabel , FormControl, Form, Alert } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";


// import { signIn, resendConfirmationCode } from "../lib/Cognito";
import ConfirmationForm from "../components/ConfirmationForm";
import { Auth } from "aws-amplify";
import FederatedLogin from "./FederatedLogin";


export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmationNeeded: false,
            errorCode: "",
            errorMessage: "",
            showError: false
        };

    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onSubmitSuccess = () => {
        this.props.userHasAuthenticated(true);
        this.props.history.push("/");
        console.log("submit success");
    }

    onSubmitFailure = async (err) => {

        if (err.code === "UserNotConfirmedException") {
            this.setState({ confirmationNeeded: true });
            try {
                await Auth.resendSignUp(this.state.email);
            } catch (err) {
                console.log("login resendConfirmationCode failure:" + JSON.stringify(err));
                this.setState({
                    showError: true,
                    errorCode: err.code,
                    errorMessage: err.message
                });
            }
        } else {
            this.setState({
                showError: true,
                errorCode: err.code,
                errorMessage: err.message
            });
            console.log("submit failure");
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.setState({ isLoading: false });
            this.onSubmitSuccess();
        } catch (err) {
            this.setState({ isLoading: false });
            this.onSubmitFailure(err);
        }

    }

    confirmationCallback = async () => {
        try {
            await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
        } catch (err) {
            this.setState({ confirmationNeeded: false });
            this.setState({
                showError: true,
                errorCode: err.code,
                errorMessage: err.message
            });

        }
    }

    handleFbLogin = () => {
        this.props.userHasAuthenticated(true);
    };

    renderConfirmationForm() {
        return (
            <ConfirmationForm
                email={this.state.email}
                confirmationCallback={this.confirmationCallback} />
        );
    }

    renderErrorAlert = () => {
        return (
            <Alert
                className="alert-danger"
                onDismiss={() => this.setState({ showError: false })}
                dismissible="true">

                <p> {this.state.errorMessage} </p>
            </Alert>
        );
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <FederatedLogin userHasAuthenticated = {this.props.userHasAuthenticated}/>
                <hr />
                {this.state.showError &&
                    this.renderErrorAlert()}
                <Form.Group controlId="email" bsSize="large">
                    <FormLabel >Email</FormLabel >
                    <Form.Control
                        autoFocus
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="password" bsSize="large">
                    <FormLabel >Password</FormLabel >
                    <Form.Control
                        value={this.state.password}
                        onChange={this.handleChange}
                        type="password"
                    />
                </Form.Group>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Login"
                    loadingText="Logging in…"
                />

            </form>);
    }

    render() {
        return (
            <div className="Login">

                {this.state.confirmationNeeded ?
                    this.renderConfirmationForm() :
                    this.renderForm()}
            </div>
        );
    }
}
