import React, { Component } from "react";
import {
    Form,
    Alert,
    FormLabel 
} from "react-bootstrap";

import LoaderButton from "./LoaderButton";
import { Auth } from "aws-amplify";

export default class ConfirmationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationCode: "",
            isLoading: false,
            errorCode: "",
            errorMessage: "",
            showError: false
        };
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });
        this.setState({ showError: false });

        try {
            await Auth.confirmSignUp(this.props.email, this.state.confirmationCode);
            await this.props.confirmationCallback();
        } catch (e) {
            console.log("confirmRegistration callback error: " + JSON.stringify(e));
            this.setState({ isLoading: false });
            this.setState({
                showError: true,
                errorCode: e.code,
                errorMessage: e.message
            });
        }


    }

    renderErrorAlert = () => {

        return (
            <Alert
                className="alert-danger"
                onDismiss={() => this.setState({ showError: false })}
                dismissible="true">

                <p>{this.state.errorMessage}</p>
            </Alert>
        )

    }


    render() {
        return (
            <form onSubmit={this.handleConfirmationSubmit}>
                {this.state.showError &&
                    this.renderErrorAlert()}
                <Form.Group controlId="confirmationCode" bsSize="large">
                    <FormLabel >Confirmation Code</FormLabel >
                    <Form.Control
                        autoFocus
                        type="tel"
                        value={this.state.confirmationCode}
                        onChange={this.handleChange}
                    />

                    <Alert variant = "primary">Please check your email for the code.</Alert>
                </Form.Group>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateConfirmationForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Verify"
                    loadingText="Verifying…"
                />
            </form>
        );
    }
}

