
import React, { Component } from "react";
import { FormControl, Form } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import config from "../config";
import AceEditor from "react-ace";
// import "ace-builds";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-beautify";

const AWS = require('aws-sdk');

export default class Code extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            code: 'console.log(\'hi\')',
            codeResult: ''
        };
    }
    runCode = (event) => {
        console.log("run code");
        this.setState({isLoading: true});
        var params = {
            //   ClientContext: "MyApp", 
            FunctionName: "TestFunction",
            InvocationType: "RequestResponse",

            Payload: JSON.stringify({ code: this.state.code }),

        };
        var lambda = new AWS.Lambda({region: 'us-east-1'});
        lambda.invoke(params, (err, data) => {
            this.setState({isLoading: false});
            if (err) console.log(err, err.stack); // an error occurred
            else console.log(data);           // successful response
            var payload = JSON.parse(data.Payload);

            console.log(payload);
            this.setState({ codeResult: payload.body });
        });
    }
    textareaChange = (event) => {
        console.log(event);
        console.log(event.target.value);
        this.setState({ code: event.target.value });
    }
    validateForm = () => {
        return true;
    }
    render = () => {
        return (
            <form>
                <AceEditor
                    placeholder="Javascript code"
                    mode="javascript"
                    theme="github"
                    name="blah2"
                    // onLoad={this.onLoad}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={this.state.code}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                    width='100%'
                    showPrintMargin={false}
                />
                <LoaderButton
                    block
                    bssize="large"
                    disabled={!this.validateForm()}
                    type="button"
                    isLoading={this.state.isLoading}
                    text="Run"
                    loadingText="Running…"
                    onClick={this.runCode}
                />
                <Form.Control
                    // componentClass="textarea"
                    placeholder="Results..."
                    value={this.state.codeResult}
                    readOnly />
            </form>
        );
    }
}