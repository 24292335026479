import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";
import Videos from "./containers/Videos";
import Code from "./containers/Code";
import NodeCode from "./components/NodeCode";
import PythonCode from "./components/PythonCode";
import JavaCode from "./components/JavaCode";
import Pricing from "./containers/Pricing"; 
import HostedLogin from "./containers/HostedLogin";




export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={HostedLogin} props={childProps} />
    <AppliedRoute path="/signup" exact component={Signup} props={childProps} />
    <AppliedRoute path="/videos" exact component={Videos} props={childProps} />
    <AppliedRoute path="/code" exact component={Code} props={childProps} />
    <AppliedRoute path="/code/node" exact component={NodeCode} props={childProps} />
    <AppliedRoute path="/code/python" exact component={PythonCode} props={childProps} />
    <AppliedRoute path="/code/java" exact component={JavaCode} props={childProps} />
    <AppliedRoute path="/pricing" exact component={Pricing} props={childProps} />
    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>;
