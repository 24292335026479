import React, { Component } from "react";
import { FormGroup, FormLabel, FormControl, Form, Alert } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";


// import { signIn, resendConfirmationCode } from "../lib/Cognito";
import ConfirmationForm from "../components/ConfirmationForm";
import { Auth } from "aws-amplify";
import FederatedLogin from "./FederatedLogin";

const AWS = require('aws-sdk');


export default class HostedLogin extends Component {
    constructor(props) {
        super(props);
         this.redirect();


    }

    async redirect() {
        try {
            await Auth.federatedSignIn();
        } catch (error) {

            console.log('hosted ui federated login error:', error);
        }
    }




    render() {
        return <LoaderButton
            id="hostedUiButton"
            block
            bssize="large"
            bsstyle="primary"
            className="hostedUiButton"
            text="Login with Hosted UI"
            onClick={this.redirect}
        // disabled={this.state.isLoading}
        />
    }
}
