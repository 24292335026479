import React, { Component } from "react";
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>CookbookCoding</h1>
          <p>A step by step guide to problem solving.</p>
        </div>
      </div>
    );
  }
}
