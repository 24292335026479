// https://serverless-stack.com/chapters/facebook-login-with-cognito-using-aws-amplify.html

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";
import config from "../config";
import { loadFacebook } from "../lib/FederatedLoginUtils";

const AWS = require('aws-sdk');

export default class FacebookButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    await loadFacebook();
    this.setState({ isLoading: false });

  }

  statusChangeCallback = response => {
    if (response.status === "connected") {
      this.handleResponse(response.authResponse);
      this.props.userHasAuthenticated(true);
    } else {
      this.handleError(response);
    }
  };

  checkLoginState = () => {
    window.FB.getLoginStatus(this.statusChangeCallback);
  };

  handleClick = () => {
    window.FB.login(this.checkLoginState, { scope: "public_profile,email" });
  };

  handleError(error) {
    console.log(error);
  }

  async handleResponse(data) {
    const { email, accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();
    const user = { email };

    this.setState({ isLoading: true });

    try {
      const creds = await Auth.federatedSignIn(
        "facebook",
        { token, expires_at },
        user
      );
      AWS.config.credentials = creds;
      this.props.userHasAuthenticated(true);
    } catch (e) {

      this.handleError(e);
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <LoaderButton
        block
        bsSize="large"
        bsStyle="primary"
        className="FacebookButton"
        text="Login with Facebook"
        onClick={this.handleClick}
        disabled={this.state.isLoading}
      />
    );
  }
}