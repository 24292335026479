import React, { Component } from "react";
import ReactPlayer from 'react-player';

export default class CustomReactPlayer extends Component {
    constructor() {
        super();
        this.state = {
            isPlaying: false,
        };
        this.ref = React.createRef();
    }

    togglePlay = () => {
        this.setState({ isPlaying: !this.state.isPlaying })
        console.log("toggle");
    }

    render() {
        return (
            <div>
                <ReactPlayer playing={this.state.isPlaying}
                    // url={this.state.url}
                    url={this.props.url}
                    ref={this.ref}
                    controls={true}
                    playsinline
                    config={{
                        file: {
                            attributes: {
                                onContextMenu: e => e.preventDefault()
                            },
                            // forceHLS: true
                        }
                    }}
                />

            </div>
        );
    }
}
