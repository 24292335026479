
import React, { Component } from "react";
import FacebookButton from "../components/FacebookButton";
import GoogleButton from "../components/GoogleButton";
import GoogleButton2 from "../components/GoogleButton2";
import LoaderButton from "../components/LoaderButton";
import HostedUI from "../components/HostedUI";

export default class FederatedLogin extends Component {

    render = () => {
        return (
            <div>
                <GoogleButton2
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <GoogleButton
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                <FacebookButton
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />
                {/* <div className="g-signin2" data-onsuccess="onSignIn"></div> */}

                <HostedUI
                    userHasAuthenticated={this.props.userHasAuthenticated}
                />

            </div>
        );
    }
}