
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import LoaderButton from "./LoaderButton";
import config from "../config";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-beautify";

const AWS = require('aws-sdk');

export default class Code extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            code: '//don\'t change class name or main method\n' +
                'public class Web{\n' +
                'public static void main(){\n' +
                'System.out.println("hi");\n' +
                '}\n' +
                '}\n',
            codeResult: '',
        };
    }

    runCode = (event) => {
        this.setState({isLoading: true});
        console.log("run code:", this.state.code);
        var params = {
            //   ClientContext: "MyApp", 
            FunctionName: "JavaCode",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({ code: this.state.code, className: 'Web', methodName: 'main' }),
        };
        console.log(params.Payload);
        var lambda = new AWS.Lambda({ region: 'us-east-1' });
        lambda.invoke(params, (err, data) => {
            this.setState({isLoading: false});
            if (err) console.log(err, err.stack); // an error occurred
            else console.log(data);           // successful response
            var payload = JSON.parse(data.Payload);

            console.log(payload);
            this.setState({ codeResult: payload.body });
        });
    }

    onChange = (event) => {
        console.log({ event: event });
        this.setState({ code: event });
    }
    validateForm = () => {
        return true;
    }

    render = () => {
        return (
            <form>
                <AceEditor
                    placeholder="Java code"
                    mode="java"
                    theme="github"
                    name="blah2"
                    // onLoad={this.onLoad}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={this.state.code}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                    width='100%'
                    showPrintMargin={false}
                />

                <LoaderButton
                    block
                    bssize="large"
                    disabled={!this.validateForm()}
                    type="button"
                    isLoading={this.state.isLoading}
                    text="Run"
                    loadingText="Running…"
                    onClick={this.runCode}
                />
                <Form.Control
                    // componentClass="textarea"
                    placeholder="Results..."
                    value={this.state.codeResult} 
                    readOnly/>
            </form>
        );
    }
}