// https://serverless-stack.com/chapters/facebook-login-with-cognito-using-aws-amplify.html

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const AWS = require('aws-sdk');

export default class GoogleButton2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }


  onClick = async () => {
    this.setState({ isLoading: true });
    try {
      const creds = await Auth.federatedSignIn({ provider: 'Google' });
      AWS.config.credentials = creds;
      this.props.userHasAuthenticated(true);
    } catch (error) {
      console.log('hosted ui federated login error:', error);
    }
    this.setState({ isLoading: false });
  }


  render() {
    return (
      <LoaderButton
        id="googleButton"
        block
        bssize="large"
        bsstyle="primary"
        className="googleButton"
        text="Login with Google2"
        onClick={this.onClick}
        disabled={this.state.isLoading}
      />


    );
  }
}