import React, { Component, Fragment } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import "./Stripe.css";
import { Form, Button, Alert } from 'react-bootstrap';
import { API, Auth } from 'aws-amplify';

const AWS = require('aws-sdk');

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = { complete: false, errorMessage: null, showError: false };
        this.submit = this.submit.bind(this);
    }

    renderErrorAlert = () => {
        return (
            <Alert
                variant="danger"
                onClose={() => this.setState({ showError: false })}
                dismissible="true">

                <p> {this.state.errorMessage} </p>
            </Alert>
        );
    }

    async submit(ev) {
        ev.preventDefault();

        const cardElement = this.props.elements.getElement('card');
        const { token, error } = await this.props.stripe.createToken({ name: this.state.nameOnCard });

        console.log("token", token);
        console.log("error", error);

        if (error) {
            this.setState({ showError: true, errorMessage: error.message })
            return
        }

        var obj = {
            "body": {
                "token": {
                    "id": token.id
                },
                "charge": {
                    "amount": "200",
                    "currency": "usd"
                }
            }
        }
        var params = {
            //   ClientContext: "MyApp", 
            FunctionName: "StripeBackend",
            InvocationType: "RequestResponse",
            Payload: JSON.stringify(obj),
        };
        // console.log(params.Payload);
        var lambda = new AWS.Lambda({ region: 'us-east-1' });
        lambda.invoke(params, (err, response) => {
            console.log('lambda error:', err);
            console.log('lambda response:', response);

            if (err) {
                this.setState({ showError: true, errorMessage: err.toString() });
                return;
            }

            if (response.StatusCode != 200) {
                this.setState({ showError: true, errorMessage: "Error communiating with server." });
                return;
            }

            const payload = JSON.parse(response.Payload);

            if (payload.statusCode != 200) {
                this.setState({ showError: true, errorMessage: payload.body.error });
                return;
            }

            this.setState({ complete: true });

        });

    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    onClick = async event => {
        try {
            event.preventDefault();


            console.log('testing...');

            try{
            var currentSession = await Auth.currentSession();
            }catch(error){
                this.setState({ showError: true, errorMessage: "You must be logged in first." });
            }

            const cardElement = this.props.elements.getElement('card');
            const { token, error } = await this.props.stripe.createToken({ name: this.state.nameOnCard });

            console.log("token", token);
            console.log("error", error);

            if (error) {
                this.setState({ showError: true, errorMessage: error.message })
                return
            }

            var obj = {
                "body": {
                    "token": {
                        "id": token.id
                    },
                    "charge": {
                        "amount": "200",
                        "currency": "usd"
                    }
                }
            }

        
            const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
            let myInit = { // OPTIONAL
                headers: { Authorization: jwt }, // OPTIONAL
                body: obj.body,
                response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                // queryStringParameters: {  // OPTIONAL
                //     name: 'param'
                // }
            }

            API.post('test', '/stripe', myInit).then(response => {
                if (response.status != 200) {
                    this.setState({ showError: true, errorMessage: response.statusText });
                    return;
                }

                this.setState({ complete: true, showError: false });
                console.log('response', response);
            }).catch(e => console.log('api error', e));
        } catch (error) {
            console.log('onclick error:', error)
        }
    }

    render() {
        if (this.state.complete) return <Alert
            variant="success">
            <p> Purchase Complete </p>
        </Alert>

        console.log("is auth", this.props.isAuthenticated);

        return (
            <Fragment>
                {!this.props.isAuthenticated && <Alert
                    variant="warning">
                    <p> You must be logged in to purchase. </p>
                </Alert>}
                <Alert
                    variant="info">
                    <p> This is a test environment. Use this credit card number for testing: </p>
                    <p> 4242 4242 4242 4242 </p>
                </Alert>
                <Form onSubmit={this.onClick}>
                    <Form.Group controlId="nameOnCard">
                        <Form.Label>Name on card</Form.Label>
                        <Form.Control type="text" placeholder="" onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="creditCardInfo">
                        <Form.Label>Card information</Form.Label>
                        <CardElement />
                    </Form.Group>
                    <Button variant="primary" type="submit" >
                        Submit
                    </Button>

                </Form>
                {this.state.showError &&
                    this.renderErrorAlert()}
            </Fragment>
        );
    }
}

export default injectStripe(CheckoutForm);