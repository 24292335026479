import React, { Component, Fragment } from "react";

import { Link, withRouter } from "react-router-dom";

import { Nav, Navbar, NavItem, NavDropdown, Form, FormControl, Button } from "react-bootstrap";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { LinkContainer } from "react-router-bootstrap";

import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import config from "./config";
import { loadGoogle, loadFacebook } from "./lib/FederatedLoginUtils";


const AWS = require('aws-sdk');

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: false
    };

  }

  async componentDidMount() {
    this.setState({ isAuthenticating: true });

    // i dont think this loading is needed but some delay is needed for the session data to be stored in local storage
    // if page loads too fast, the data will not be in local storage yet
    await loadFacebook();
    await loadGoogle();

    try {
      // await Auth.currentSession();
      var cognitoUser = await Auth.currentAuthenticatedUser();
      var creds = await Auth.currentCredentials();
      AWS.config.update({ credentials: creds });
      console.log('cognitoUser:', cognitoUser);
      console.log('credentials:', AWS.config.credentials);
      this.userHasAuthenticated(true);
    } catch (e) {
      console.log("could not load user");
      //load unauthenticated user credentials
      var guestCreds = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
      });
      AWS.config.update({ credentials: guestCreds, region: 'us-east-1' });
      console.log('AWS.config.credentials, ', AWS.config.credentials);
      console.log(e);
    }

    this.setState({ isAuthenticating: false });
  }




  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    delete AWS.config.credentials;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
    });

    console.log('credentials:', AWS.config.credentials);
    this.props.history.push("/");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      !this.state.isAuthenticating &&
      <div className="App container">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">CookbookCoding</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link>
                <LinkContainer to="/">
                  <NavItem>Home</NavItem>
                </LinkContainer>
              </Nav.Link>
              <Nav.Link>
                <LinkContainer to="/videos">
                  <NavItem>Videos</NavItem>
                </LinkContainer>
              </Nav.Link>

              <Nav.Link>
                <LinkContainer to="/code">
                  <NavItem>Code</NavItem>
                </LinkContainer>
              </Nav.Link>
              <Nav.Link>
                <LinkContainer to="/pricing">
                  <NavItem>Pricing</NavItem>
                </LinkContainer>
              </Nav.Link>
            </Nav>
            <Nav >

              {this.state.isAuthenticated ?
                <Nav.Link onClick={this.handleLogout}>Logout</Nav.Link> :
                <Fragment>
                  <Nav.Link>
                    <LinkContainer to="/signup">
                      <NavItem>Signup</NavItem>
                    </LinkContainer>
                  </Nav.Link>
                  <Nav.Link>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Nav.Link>
                </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }


}

export default withRouter(App);

