import config from "../config";

function loadGoogleApi() {
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://apis.google.com/js/platform.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-lib'));

    return waitForGoogleApi();
}

function waitForGoogleApi() {
    return new Promise((res, rej) => {
        const hasLoaded = () => {
            if (window.gapi) {
                res();
            } else {
                setTimeout(hasLoaded, 300);
            }
        };
        hasLoaded();
    });
}

function loadGoogleAuth() {
    if (!window.gapi.auth2) {
        window.gapi.load('auth2', () => {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            var auth2 = window.gapi.auth2.init({
                // client_id: '556838507657-8d6uqta3d6mvlo5jea72ptu0er2fhu9m.apps.googleusercontent.com',
                client_id: config.social.Google,
                cookiepolicy: 'single_host_origin',
                // Request scopes in addition to 'profile' and 'email'
                //scope: 'additional_scope'
            });

        });
    }

    return waitForGoogleAuth();
}

function waitForGoogleAuth() {
    return new Promise((res, rej) => {
        const hasLoaded = () => {
            if (window.gapi.auth2) {
                res();
            } else {
                setTimeout(hasLoaded, 300);
            }
        };
        hasLoaded();
    });
}

function loadGoogle() {
    return loadGoogleApi().then(loadGoogleAuth);
}

function loadFacebookSDK() {
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: config.social.FB,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v3.1'
        });
    };

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    return waitForFbInit();
}

function waitForFbInit() {
    return new Promise((res, rej) => {
        const hasFbLoaded = () => {
            if (window.FB) {
                res();
            } else {
                setTimeout(hasFbLoaded, 300);
            }
        };
        hasFbLoaded();
    });
}

function loadFacebook() {
    return loadFacebookSDK();
}

export {
    loadGoogle, loadFacebook
}