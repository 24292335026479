import React, {Component} from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../components/CheckoutForm';
import config from '../config';
export default class Pricing extends Component {
  render() {
    return (
      <StripeProvider apiKey={config.stripe.API_KEY}>
        <div className="example">
          {/* <h1>Credit Card</h1> */}
          <Elements>
            <CheckoutForm isAuthenticated={this.props.isAuthenticated}/>
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}
