import React, { Component, Fragment } from "react";
import NodeCode from "../components/NodeCode";
import PythonCode from "../components/PythonCode";
import JavaCode from "../components/JavaCode";
import { Route, Switch, Link } from "react-router-dom";
import { DropdownButton, MenuItem, Dropdown } from 'react-bootstrap';
export default class Code extends Component {

    constructor(props) {
        super(props);
        this.state = { language: "Node" };
        this.langComponentDict = { "Node": <NodeCode />, "Python": <PythonCode />, "Java": <JavaCode /> }
    }

    renderCoder = () => {
        return this.langComponentDict[this.state.language];
        // return <NodeCode></NodeCode>
    }

    render = () => {
        // let coder = this.renderCoder();
        return (
            // <DropdownButton
            //     bsStyle="default"
            //     title="Language"
            //     // key={i}
            //     // id={`dropdown-basic-${i}`}
            // >
            //     <MenuItem eventKey="1">Node</MenuItem>
            //     <MenuItem eventKey="2">Python</MenuItem>
            //     <MenuItem eventKey="3">Java</MenuItem>
            // </DropdownButton>
            <Fragment>
                <Dropdown>
                    <DropdownButton id="dropdown-basic-button" title={this.state.language}>
                        <Dropdown.Item onSelect = {()=> this.setState({language: "Node"})}>Node</Dropdown.Item>
                        <Dropdown.Item onSelect = {()=> this.setState({language: "Python"})}>Python</Dropdown.Item>
                        <Dropdown.Item onSelect = {()=> this.setState({language: "Java"})}>Java</Dropdown.Item>
                    </DropdownButton>
                </Dropdown>
                {/* <NodeCode></NodeCode> */}
                {this.renderCoder()}
                {/* {coder} */}

            </Fragment>
            // <div>
            //     <Link to="/code/node">Node</Link>
            //     <Link to="/code/python">Python</Link>
            //     <Link to="/code/java">Java</Link>
            // </div>
        );
    }
}