export default {
  s3: {
    REGION: "us-east-1",
    BUCKET: "code.walterho.net-videos"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://840j8mstg8.execute-api.us-east-1.amazonaws.com/dev",
    NAME: "test"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_1rGYzRpv8",
    APP_CLIENT_ID: "1omkuik3mp90enjui2p929ovnb",
    IDENTITY_POOL_ID: "us-east-1:193c3183-237b-4469-a337-592dc8554641"
  },
  social: {
    FB: "2354604088189559",
    Google: "190010917802-7bkurjlkfe0e2po6a930nq9qb4p6oshf.apps.googleusercontent.com"
  },
  stripe: {
    API_KEY: "pk_test_4wH66EJyRzlHJMLu2iHnOVVR00LoWjmser"
  },
  oauth: {
    DOMAIN: 'dev-code-walterho-net.auth.us-east-1.amazoncognito.com',
    SCOPE: ['email'],
    REDIRECT_SIGN_IN: 'https://code.walterho.net/',
    REDIRECT_SIGN_OUT: 'https://code.walterho.net/',
    RESPONSE_TYPE: 'code'
  }
};
