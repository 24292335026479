import React, { Component, Fragment } from "react";
import ReactPlayer from 'react-player';
import { Button, Card } from 'react-bootstrap';
import Amplify, { Auth, Storage } from 'aws-amplify';
import CustomReactPlayer from "../components/CustomReactPlayer";

export default class Videos extends Component {
    constructor() {
        super();
        this.state = {
            isPlaying: false,
            url: ""
            // url = "https://s3.amazonaws.com/code.walterho.net-videos/fire-360.mp4"
        };
        this.ref = React.createRef();
    }

    componentDidMount = async () => {
        await Auth.currentCredentials()
            .then(d => console.log('data: ', d))
            .catch(e => console.log('error: ', e))
        // Storage.configure({ level: 'private' });
        // Storage.get("inputs_and_outputs0001-10893.mp4").then(
        //     (res) => {
        //         console.log("res: ", res)
        //         this.setState({ url: res });
        //     }
        // ).catch(
        //     (err) => console.log("err:", err)
        // );
        
        // Storage.get("arrays_draft_first_0001-10797.mp4").then(
        //     (res) => {
        //         console.log("res: ", res)
        //         this.setState({ url: res });
        //     }
        // ).catch(
        //     (err) => console.log("err:", err)
        // );


    }

    togglePlay = () => {
        this.setState({ isPlaying: !this.state.isPlaying })
        console.log("toggle");
    }
    render() {
        return (
            <Fragment>
                
                {/* <Card bg="light" text="black" >
                    <Card.Header>Inputs + Outputs</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Learn about the inputs and outputs.
                        </Card.Text>
                        <CustomReactPlayer url="https://s3.amazonaws.com/code.walterho.net-media-convert/convert/inputs_and_outputs0001-10893.m3u8" />
                    </Card.Body>
                </Card> */}

                <Card bg="light" text="black" >
                    <Card.Header>Arrays</Card.Header>
                    <Card.Body>
                        {/* <Card.Title>Primary Card Title</Card.Title> */}
                        {/* <Card.Text>
                            Learn about the inputs and outputs.
                        </Card.Text> */}
                        <CustomReactPlayer url="https://s3.amazonaws.com/code.walterho.net-media-convert/arrays_draft_first_0001-10797.mp4" />
                    </Card.Body>
                    
                </Card>
                
            </Fragment>
        );
    }
}
