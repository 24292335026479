import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import config from "./config";
import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    // cookieStorage: {
    //   // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //   domain: 'localhost:3000',
    //   // OPTIONAL - Cookie path
    //   path: '/',
    //   // OPTIONAL - Cookie expiration in days
    //   expires: 365,
    //   // OPTIONAL - Cookie secure flag
    //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    //   secure: true
    // },
  },
  // Storage: {
  //   region: config.s3.REGION,
  //   bucket: config.s3.BUCKET,
  //   identityPoolId: config.cognito.IDENTITY_POOL_ID
  // },
  Storage: {
    AWSS3: {
      bucket: config.s3.BUCKET, //REQUIRED -  Amazon S3 bucket
      region: config.s3.REGION, //OPTIONAL -  Amazon service region
    }
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  },
  oauth: {
    domain: config.oauth.DOMAIN,
    scope: config.oauth.SCOPE,
    redirectSignIn: config.oauth.REDIRECT_SIGN_IN,
    redirectSignOut: config.oauth.REDIRECT_SIGN_OUT,
    responseType: config.oauth.RESPONSE_TYPE // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
});


ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
