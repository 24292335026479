import React, { Component } from "react";
import {
  // FormGroup,
  // FormControl,
  FormLabel, 
  Form,
  Alert
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import ConfirmationForm from "../components/ConfirmationForm";
import { Auth } from "aws-amplify";


export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      confirmPassword: "",
      confirmationNeeded: false,
      errorCode: "",
      errorMessage: "",
      showError: false
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({
        confirmationNeeded: true
      });
    } catch (err) {
      this.setState({
        showError: true,
        errorCode: err.code,
        errorMessage: err.message
      });
    }
    this.setState({ isLoading: false });
  }

  confirmationCallback = async () => {
    
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (err) {
      this.setState({ confirmationNeeded: false });
      this.setState({ isLoading: false });
      this.setState({
        showError: true,
        errorCode: err.code,
        errorMessage: err.message
      });
    }
  }

  renderConfirmationForm = () => {
    return (
      <ConfirmationForm
        email={this.state.email}
        confirmationCallback={this.confirmationCallback} />
    );
  }

  renderErrorAlert = () => {
    return (
      <Alert
        className="alert-danger"
        onDismiss={() => this.setState({ showError: false })}
        dismissible="true">

        <p>{this.state.errorMessage}</p>
      </Alert>
    );

  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.showError && this.renderErrorAlert()}
        <Form.Group controlId="email" bsSize="large">
          <FormLabel >Email</FormLabel >
          <Form.Control
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group controlId="password" bsSize="large">
          <FormLabel >Password</FormLabel >
          <Form.Control
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" bsSize="large">
          <FormLabel >Confirm Password</FormLabel >
          <Form.Control
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </Form.Group>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.confirmationNeeded ?
          this.renderConfirmationForm()
          : this.renderForm()}
      </div>
    );
  }
}
