// https://serverless-stack.com/chapters/facebook-login-with-cognito-using-aws-amplify.html

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";
import config from "../config";
import { loadGoogle } from "../lib/FederatedLoginUtils";
import { AWSCredentials } from "@aws-amplify/api/lib/types";

const AWS = require('aws-sdk');

export default class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  async componentDidMount() {
    await loadGoogle();

    await this.waitForButtonRendered();
    var element = document.getElementById('googleLoginButton');
    window.gapi.auth2.getAuthInstance().attachClickHandler(element, {}, this.handleResponse, this.handleError);

    this.setState({ isLoading: false });
  }

  handleResponse = async (googleUser) => {
    const user = {
      email: googleUser.getBasicProfile().getEmail()
    };
    const response = {
      token: googleUser.getAuthResponse().id_token,
      expires_at: googleUser.getAuthResponse().expires_in
    };
    this.setState({ isLoading: true });
    try {
      // const creds = await Auth.federatedSignIn("google", response, user);
      // AWS.config.credentials = creds;
      const creds = new AWSCredentials({
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID,
        Logins: {
          'accounts.google.com': googleUser.getAuthResponse().id_token,
        }
      });
      this.props.userHasAuthenticated(true);
    } catch (error) {

      console.log('google federated login error:', error);
    }

    this.setState({ isLoading: false });

  }

  handleError(error) {
    console.log(JSON.stringify(error, undefined, 2));
  }

  waitForButtonRendered() {
    return new Promise((res, rej) => {
      const hasLoaded = () => {
        if (document.getElementById('googleLoginButton')) {
          console.log('found element');
          res();
        } else {
          setTimeout(hasLoaded, 300);
        }
      };
      hasLoaded();
    });
  }

  render() {
    return (
      <LoaderButton
        id="googleLoginButton"
        block
        bsSize="large"
        bsStyle="primary"
        className="GoogleButton"
        text="Login with Google"
        // onClick={this.handleClick}
        disabled={this.state.isLoading}
      />


    );
  }
}