// https://serverless-stack.com/chapters/facebook-login-with-cognito-using-aws-amplify.html

import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "./LoaderButton";

const AWS = require('aws-sdk');

export default class HostedUI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }


  onClick = async ()=> {
    try {
      // const creds = await Auth.federatedSignIn();
      // AWS.config.credentials = creds;
      // AWS.config.update({credentials: creds});
      // this.props.userHasAuthenticated(true);

      Auth.federatedSignIn().then(creds => {
        AWS.config.update({credentials: creds});
        console.log('after fed sign in:', creds);
      })
    } catch (error) {

      console.log('hosted ui federated login error:', error);
    }
  }


  render() {
    return (
      <LoaderButton
        id="hostedUiButton"
        block
        bssize="large"
        bsstyle="primary"
        className="hostedUiButton"
        text="Login with Hosted UI"
        onClick={this.onClick}
        // disabled={this.state.isLoading}
      />


    );
  }
}